function setEqualHeight() {
    var currentBreakpoint = getNumSkelSize();
    if (currentBreakpoint > 3) {
        $(".banner-enako").matchHeight({
            target: $("#banner-wrapper")
        });
    } else {
        $(".banner-enako").matchHeight({
            remove: true
        });
    }
}

function scaleMenu() {

    var currentBreakpoint = getNumSkelSize(),
        menuItemHeight;
    if (currentBreakpoint > 3) {
        menuItemHeight = ($("#banner-wrapper").innerHeight() - (4 * 4)) / 5;
        $("#banner-menu-wrapper .menuitem-image-container").css("height", menuItemHeight + "px");
    } else {
        $("#banner-menu-wrapper .menuitem-image-container").css("height", "");
    }


}

$(window).on("load", function() {
    setEqualHeight();
    scaleMenu();
});

$(window).resize(function() {
    // $.fn.matchHeight._update();
    setEqualHeight();
    scaleMenu();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});
